<template>
  <div class="forgotPassword">
    <main>
      <router-link to="/">
        <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee - Home" />
      </router-link>
      <div class="content">
        <h2>Schau in dein Postfach!</h2>
        <p>
          Wir haben dir eine E-Mail zur Zurücksetzung deines Passworts gesendet. Klicke auf den Link in der E-Mail, um ein neues Passwort vergeben zu können.
        </p>
        <router-link to="/"><button class="button__transparent">Zurück zum Login</button></router-link>
      </div>
    </main>
    <AppFooterRegistration />
  </div>
</template>

<script>
import AppFooterRegistration from "@/components/AppFooterRegistration.vue";

export default {
  name: "ForgotPassword-2",
  components: {
    AppFooterRegistration,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  height: calc(100vh - 83px);
  display: flex;
  justify-content: center;
  align-items: center;
  .logo__large {
    position: fixed;
    top: 40px;
    left: 48px;
    @media screen and (max-width: 960px) {
      top: 20px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .content {
    max-width: 648px;
    @media screen and (max-width: 960px) {
      width: 100%;
      padding: 24px;
      align-self: flex-end;
      margin-bottom: 40px;
    }
    h2 {
      @media screen and (max-width: 960px) {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 12px;
      }
    }
    p {
      margin-bottom: 40px;
      @media screen and (max-width: 960px) {
        margin-bottom: 80px;
      }
    }
  }
}
</style>
